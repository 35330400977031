import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/Seo";

import { Hero } from "../components/sections/Hero";
import { Features } from "../components/sections/Features";
import { Footer } from "../components/sections/Footer";
import { Cta } from "../components/sections/Cta";
import { Pricing } from "../components/sections/Pricing";
import { How } from "../components/sections/How";
import { Faq } from "../components/sections/Faq";
import { Video } from "../components/sections/Video";
import { UseCases } from "../components/sections/UseCases";
// import { Pixels } from "../components/Pixels";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      socialImage: file(relativePath: { eq: "social-image.jpg" }) {
        childImageSharp {
          resize(width: 1200) {
            src
            height
            width
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO
        title="Boost your ad spending and ROAS converting third-party scripts into first-party scripts without code"
        image={data.socialImage.childImageSharp.resize}
      />
      <Hero />
      <Features />
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <Video />
      </div>
      <Pricing />
      <UseCases />
      <Cta />
      <How />
      <Faq />
      <Footer />
    </Layout>
  );
};

export default IndexPage;
