import React from "react";
import { Link } from "gatsby";

import GoogleTagmanger from "../../images/google-tagmanager.svg";
import GoogleAnalytics from "../../images/google-analytics.svg";
import Facebook from "../../images/facebook.svg";
import Intercom from "../../images/intercom.svg";
import Hubspot from "../../images/hubspot.svg";
import GoogleAds from "../../images/google-ads.svg";
import Segment from "../../images/segment.svg";
import Optimizely from "../../images/optimizely.svg";

export const WorksWith = () => {
  return (
    <div id="tools" className="bg-white rounded-xl mt-12">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div>
            <h2 className="text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
              Works with the tools you love
            </h2>
            <p className="mt-3 max-w-3xl text-lg leading-7 text-gray-500">
              Tagrush is designed to make website tags and pixels resistant to
              firewalls and filter lists from Ad Blockers. So you get{" "}
              <strong className="text-indigo-600">100% of the data</strong> to
              improve your Google and Facebook campaigns.
            </p>
            <div className="mt-8 sm:flex">
              <div className="rounded-md shadow">
                <Link
                  className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                  to="/signup/"
                >
                  Start now for free
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-8 grid grid-cols-2 gap-1 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
            <div className="col-span-1 flex justify-center py-8 px-2 bg-gray-50">
              <Facebook className="max-h-12" />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-2 bg-gray-50">
              <GoogleAds className="max-h-12" />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-2 bg-gray-50">
              <GoogleTagmanger className="max-h-12" />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-2 bg-gray-50">
              <GoogleAnalytics className="max-h-12" />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-2 bg-gray-50">
              <Segment className="max-h-12" />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-2 bg-gray-50">
              <Intercom className="max-h-12" />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-2 bg-gray-50">
              <Hubspot className="max-h-12" />
            </div>
            <div className="col-span-1 flex justify-center py-8 px-2 bg-gray-50">
              <Optimizely className="max-h-12" viewBox="0 0 100 100" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
