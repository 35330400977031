import React from "react";

//import IconCheck from "../../icons/icon-check.svg";

export const UseCases = () => {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">
            Tagrush unlocks your pixel data
          </h2>
          <p className="mt-4 text-lg text-gray-500">
            Unlock blocked pixels and third-party scripts to improve your data
            and customer experience. Let all your visitors interact with all
            your services.
          </p>
        </div>
        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
          <div className="flex">
            <svg
              className="flex-shrink-0 h-6 w-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="ml-3">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Advertising
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                Optimize your Facebook and Google campaigns with more conversion
                data to improve your ROAS and ROI.
              </dd>
            </div>
          </div>
          <div className="flex">
            <svg
              className="flex-shrink-0 h-6 w-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="ml-3">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Retargeting
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                Shield retargeting pixels and increase the size of your
                retargeting lists to achieve greater ROI.
              </dd>
            </div>
          </div>

          <div className="flex">
            <svg
              className="flex-shrink-0 h-6 w-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="ml-3">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Analytics
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                Unblock analytics third-party scripts like Google Analytics or
                Amplitude to improve your reports and analysis.
              </dd>
            </div>
          </div>

          <div className="flex">
            <svg
              className="flex-shrink-0 h-6 w-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="ml-3">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Customer Success
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                Support all your visitors with your web messaging apps and live
                chats and improve their experience.
              </dd>
            </div>
          </div>

          <div className="flex">
            <svg
              className="flex-shrink-0 h-6 w-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="ml-3">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Attribution
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                Improve the data of attribution tools for better insights to
                scale your business.
              </dd>
            </div>
          </div>

          <div className="flex">
            <svg
              className="flex-shrink-0 h-6 w-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="ml-3">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Performance Monitor
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                Track errors and performance of all your visitors to understand
                better the business impact of your software performance.
              </dd>
            </div>
          </div>

          <div className="flex">
            <svg
              className="flex-shrink-0 h-6 w-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="ml-3">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                A/B Testing
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                Run A/B tests for all your visitors and get better insights and
                increase your conversion.
              </dd>
            </div>
          </div>

          <div className="flex">
            <svg
              className="flex-shrink-0 h-6 w-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="ml-3">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Tag Managers
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                Unlock Google Tag Manager to quickly and easily update tags and
                code snippets for all your visitors.
              </dd>
            </div>
          </div>
        </dl>
      </div>
    </div>
  );
};
