import React from "react";
//import Img from "gatsby-image";

import { useStaticQuery, graphql } from "gatsby";
import { WorksWith } from "../sections/WorksWith";

// import IconChart from "../../icons/icon-business-organization-chart.svg";
import IconStopwatch from "../../icons/icon-time-stopwatch.svg";
import IconTarget from "../../icons/icon-business-target.svg";
import IconPeople from "../../icons/icon-people-people.svg";
// import IconLanguage from "../../icons/icon-ui-3-language.svg";
// import IconStop from "../../icons/icon-politics-oath.svg";
import IconFlash from "../../icons/icon-flash.svg";
import IconCog from "../../icons/icon-cog.svg";
import IconCode from "../../icons/icon-code.svg";
import SceneLoading from "../../images/scene-fast-loading.svg";
import SceneData from "../../images/scene-data.svg";

// import BgWaves from "../../images/waves.svg";

export const Features = () => {
  const data = useStaticQuery(graphql`
    query {
      feature1Image: file(relativePath: { eq: "creative-laptop.jpg" }) {
        childImageSharp {
          fixed(width: 490, height: 490, quality: 90) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      feature2Image: file(relativePath: { eq: "lights.jpg" }) {
        childImageSharp {
          fixed(width: 490, height: 490, quality: 90) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return (
    <div
      id="features"
      className="py-16 overflow-hidden lg:py-24 bg-gray-50"
      style={{
        backgroundPosition: `0% 27%`,
        backgroundImage: `url('/images/waves.svg')`,
        backgroundRepeat: `no-repeat`,
      }}
    >
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
        <div className="relative">
          <h3 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            47% of internet users globally use content blockers
          </h3>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-gray-500">
            Firewalls and other filters like Ad Blockers block website pixels
            and scripts like Facebook Pixel, Google Tag Manager or Google
            Analytics. The gap of missing visitors between third-party tools and
            server logs can vary from 10% to 40% making your data less accurate.
          </p>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-gray-500">
            Less data means less optimization potential for your campaigns.
          </p>
        </div>

        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <h2 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
              Boost the data of your pixels.
            </h2>
            <p className="mt-3 text-lg leading-7 text-gray-500">
              Optimize your ad spend routing 100% of the data of your visitors
              to Facebook, Google and any third-party script.
            </p>
            <ul className="mt-10">
              <li>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <IconFlash className="fill-current text-white" />
                    </div>
                  </div>
                  <div className="ml-4">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Optimize your ad spending and marketing ROI
                    </h3>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Improve your campaigns and conversions with more data
                      levering all your third-party website solutions and
                      optimization algorithms.
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <IconPeople className="fill-current text-white" />
                    </div>
                  </div>
                  <div className="ml-4">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Avoid missing visitors in your tools
                    </h3>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Make your website visitor data and reports more accurate
                      removing the gap of invisible visitors.
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <IconTarget className="fill-current text-white" />
                    </div>
                  </div>
                  <div className="ml-4">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Make better decisions with more data
                    </h3>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Rely on your analytics and business intelligence tools to
                      understand better your website visitors and campaigns.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="mt-10 -mx-6 lg:mx-8 relative lg:mt-0">
            <SceneData className="fill-current text-white" />
          </div>
        </div>

        <WorksWith />

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h2 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                Unlock all your third-party tools
              </h2>
              <p className="mt-3 text-lg leading-7 text-gray-500">
                Shield all your third-party scripts on your website with our
                Shield Proxy Network.
              </p>

              <ul className="mt-10">
                <li>
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                        <IconCog className="fill-current text-white" />
                      </div>
                    </div>
                    <div className="ml-4">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Improve the experience of all your visitors
                      </h3>
                      <p className="mt-2 text-base leading-6 text-gray-500">
                        Boost the user experience of your invisible visitors
                        letting them interact with all all your third-party
                        website solutions.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="mt-10">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                        <IconStopwatch className="fill-current text-white" />
                      </div>
                    </div>
                    <div className="ml-4">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Improve your page speed for SEO
                      </h3>
                      <p className="mt-2 text-base leading-6 text-gray-500">
                        Set long-term caching policies and lower the script
                        latency of all third-party scripts.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="mt-10">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                        <IconCode className="fill-current text-white" />
                      </div>
                    </div>
                    <div className="ml-4">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Add tags to any third-party website solution
                      </h3>
                      <p className="mt-2 text-base leading-6 text-gray-500">
                        Add your tags like Google Tag Manager or Google
                        Analytics to third party website of your domain or
                        legacy sites.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div className="mt-12 -mx-6 lg:mx-8 relative lg:mt-0 lg:col-start-1">
              <SceneLoading className="fill-current text-white" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
