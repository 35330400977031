import React from "react";

import IconLanguage from "../../icons/icon-ui-3-language.svg";
import IconFlash from "../../icons/icon-flash.svg";
import IconCode from "../../icons/icon-code.svg";

export const How = () => {
  return (
    <div id="how" className="pt-12 pb-24 bg-white">
      <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-screen-xl lg:px-8">
        <div className="max-w-3xl mx-auto text-center lg:pb-12">
          <h2 className="text-3xl leading-9 font-extrabold text-gray-900">
            How it works
          </h2>
        </div>
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <IconLanguage className="fill-current text-white" />
            </div>
            <div className="mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Setup your DNS
              </h3>
              <p className="mt-2 text-base leading-6 text-gray-500">
                Set up a <strong>CNAME</strong> to our global network of proxy
                servers.
              </p>
            </div>
          </div>
          <div className="mt-10 lg:mt-0">
            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <IconCode className="fill-current text-white" />
            </div>
            <div className="mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Add a script to your website
              </h3>
              <p className="mt-2 text-base leading-6 text-gray-500">
                Place a script in front of your other scripts.{" "}
              </p>{" "}
              <p className="mt-2 text-base leading-6 text-gray-500">
                In our <strong>Growth Plan</strong> this step is done
                automaticly!
              </p>
            </div>
          </div>
          <div className="mt-10 lg:mt-0">
            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <IconFlash className="fill-current text-white" />
            </div>
            <div className="mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                You&apos;re good to go!
              </h3>
              <p className="mt-2 text-base leading-6 text-gray-500">
                Your Google and Facebook Pixel are now shielded by Tagrush.
              </p>
              <p className="mt-2 text-base leading-6 text-gray-500">
                In our <strong>Growth Plan</strong> you can shield 50+
                third-party scripts such as Segment or Optimizely!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
