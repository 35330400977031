import React from "react";
export const Faq = () => {
  return (
    <div id="faq" className="bg-gray-50">
      <div className="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <h2 className="text-3xl leading-9 font-extrabold text-gray-900 text-center">
          Frequently asked questions
        </h2>
        <div className="mt-12">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:col-gap-8 md:row-gap-12 lg:grid-cols-3">
            <div className="space-y-2">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Does Tagrush affect other scripts of my site?
              </dt>
              <dd className="text-base leading-6 text-gray-500">
                It does not affect regular traffic in any way. We do not
                interfere with your website traffic and only re-route the
                third-party scripts traffic.
              </dd>
            </div>
            <div className="space-y-2">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Does Tagrush always recover 30%? Could it be 60%?
              </dt>
              <dd className="text-base leading-6 text-gray-500">
                It depends on your traffic type, the percentage ranges from 5%
                to as high as 60%.
              </dd>
            </div>
            <div className="space-y-2">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Does Tagrush slow down my website?
              </dt>
              <dd className="text-base leading-6 text-gray-500">
                It is the opposite. In the Growth Plan Tagrush makes your
                website faster converting third-party scripts to first-party
                scripts reducing the impact of the scripts on your website.
              </dd>
            </div>
            <div className="space-y-2">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Is Tagrush GDPR or CCPA compatible.
              </dt>
              <dd className="text-base leading-6 text-gray-500">
                Yes. Tagrush is GDPR or CCPA compatible. So, you get more data
                respecting the data privacy of your website visitors.
              </dd>
            </div>
            <div className="space-y-2">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Does Tagrush give me new insights.
              </dt>
              <dd className="text-base leading-6 text-gray-500">
                Yes, it depends on your traffic type - analyzing 100% of your
                traffic is much more accurate than without the missing visitors.
              </dd>
            </div>
            <div className="space-y-2">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Why is a new subdomain needed?
              </dt>
              <dd className="text-base leading-6 text-gray-500">
                The new subdomain is needed in the Lite Plan to make your
                third-party scripts act like a first-party. In the Growth Plan
                no subdomain is needed.
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};
